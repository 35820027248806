import {ForgotPasswordCompleteStore} from "./forgot.password.complete.store";
import {ForgotPasswordService} from "../../../../../services/forgot-password/forgot.password.service";
import queryString from "querystring";

export class ForgotPasswordCompleteController {
    constructor(
        private readonly _forgotPasswordCompleteStore: ForgotPasswordCompleteStore,
        private readonly _forgotPasswordService: ForgotPasswordService
    ) {
        this.init();
    }

    private async init(): Promise<void> {
        // this._forgotPasswordCompleteStore.setInited(false);
        // this._forgotPasswordCompleteStore.setCompleted(false);
        const params: any = queryString.parse(window.location.search.substr(1));
        try {
            await this._forgotPasswordService.checkIfComplete({token: params.token});
            this._forgotPasswordCompleteStore.setCompleted(true);
        } catch (e) {
            this._forgotPasswordCompleteStore.setCompleted(false);
        }
    }
}